import React , { useState, useRef } from 'react'
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'js-cookie';

// Some customization, only if necessary, it is not mandatory
const HeaderSection = styled.div`
  header {
    position: sticky;

    // this align the "Create" button
    .content-container {
      font-size: 1rem;
      border: 0;
      margin: 0;
      height: fit-content;
      align-items: start;
      flex-direction: row;
    }

    a {
      cursor: pointer;
    }
  }
`;

const messages = {
  DEFAULT: "Blank or Invalid faculty group specified.",
  NOTFOUND: "Page Not Found.",
  ERROR: "An Unknown error occured.",
  REDIRECT: "Redirecting..."
};

function ErrorPage(props) {
  console.log(props);
  const history = useHistory();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(Cookies.get('username'));
  const stateRef = useRef();

  var message = messages.DEFAULT;
  if ( props.error === "404")
    message = messages.NOTFOUND;
  else if ( props.error === "500")
    message = messages.ERROR;
  else if ( props.error === "304")
    message = messages.REDIRECT;

  return (
    <div>
      <div className="main-container">
        <div className="container">
          <br />
          <div className="row alert alert-warning">
            <div className="col-xs-4">
              <FontAwesomeIcon icon="exclamation-triangle" size="2x" />
            </div>
            <div className="col-xs-8">
              <b> { message } </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default ErrorPage;
