import './App.css';
import React from 'react'

import DefaultRoute from "./core/components/ErrorPage";
import TargetedRoute from "./routes/TargetedRoute";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect as redirect
} from 'react-router-dom';

library.add(faExclamationTriangle);

function App() {
  let path = window.location.pathname;
  let host = window.location.host;
  let parts = host.split(".");
  let subdomain = parts[0];

  // Remove trailing slash
  if (path.length > 1 && /\/$/.test(path)) {
    window.location.pathname = path.slice(0, -1);
  }

  if ( subdomain === "faculty" || subdomain === "lab" || subdomain.split(":")[0] === "localhost"  ) {
    //console.log("Default site: "+subdomain);
    //console.log("Path: "+path);

    return (
      <Router>
        <Switch>
          <Route exact path="/:group"  render={(props) => <TargetedRoute {...props} custom={false} />} />
          <Route exact path="/:group/*" render={(props) => <TargetedRoute {...props} custom={false} />} />
          <Route exact path="/"  render={(props) => <TargetedRoute {...props} custom={false} />} /> 
        </Switch>
      </Router>
    );
  }
  else {
    //console.log("Custom site: "+subdomain);
    //console.log("Path: "+path);
    return (
      <Router>
        <Switch>
          <Route exact path="/*" render={(props) => <TargetedRoute {...props} page={path} group={subdomain} custom={true} />} />
        </Switch>
      </Router>
    );
  }

}

export default App;
