import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import styled from "styled-components";
// core
import { ASUHeader } from "@asu-design-system/component-header/dist/asuHeader.es";
import { Footer } from "../Footer";

import desktopLogo from "../../../assets/img/arizona-state-university-logo-vertical.png";
import mobileLogo from "../../../assets/img/arizona-state-university-logo.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';

import parseHtml from 'html-react-parser';
import { stripHtml } from "string-strip-html";
import { isInternalLink } from 'is-internal-link'

import ErrorPage from "../ErrorPage";

// Some customization, only if necessary, it is not mandatory
const HeaderSection = styled.div`
  header {
    position: sticky;

    // this align the "Create" button
    .content-container {
      font-size: 1rem;
      border: 0;
      margin: 0;
      height: fit-content;
      align-items: start;
      flex-direction: row;
    }

    a {
      cursor: pointer;
    }
  }
`;

function Content(props) {
  //console.log(props);
  const history = useHistory();
  const location = useLocation();

  const [isAuthenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(Cookies.get('username'));
  const [targetNID, setTargetNID] = useState();
  const [menu, setMenu] = useState(props.data.menu);
  const [isCustom, setCustom] = useState(props.custom)

  const handleNavClick=(e)=> {
    e.preventDefault();
    const {result} = stripHtml(e.target.innerHTML);
    var nid = 0;
    // unity header component doesn't allow for adding attributes to links,
    // so we must find the nid by matching the title
    for (var i=0;i<menu.length;i++) {
      if ( result === menu[i].title ) {
        nid = menu[i].nid;
        setTargetNID(nid);
        if ( isCustom )
          history.push(menu[i].alias);
        else 
          history.push("/"+props.group+menu[i].alias);
      }
    }
  }

  // Builds navigation links and detects current page to load based on requested path.  Assumes the first link for the root page.
  const buildNav=()=> {
    var navTree = [];
    if ( Array.isArray(menu) )
      for ( var i=0;i<menu.length;i++) {
        let title  = menu[i].title;
        let nid = menu[i].nid;
        let alias = menu[i].alias;

        let path = decodeURIComponent(window.location.pathname);

        if ( isInternalLink(alias) ) {
          var isSelected = false;
          if ( 
            ( isCustom && ( path === alias || ( i === 0 && path === "/" ) ) ) 
            || 
            ( !isCustom && ( path === "/"+props.group+alias || ( i === 0 && path === "/"+props.group ) ) ) )
          {
            isSelected = true;
            if ( targetNID !== nid ) setTargetNID(nid);
          }
          navTree.push({
            onClick: (e) => handleNavClick(e),
            text: title,
            href: ( isCustom ? "/"+alias : "/"+props.group+alias ),
            type: "",
            selected: isSelected
          });
        } else {
          navTree.push({
            text: title,
            href: alias,
            class: "externalLink"
          });
        }
      }

    return navTree;
  }

  // unity header component doesn't allow for adding attributes to links so we have to
  // use the class "externalLink" to identify and modify external links to open in a new tab.
  const setExternalTargets = () =>
  {
    var links = Array.prototype.slice.apply(document.querySelectorAll("a.externalLink"));
    links.forEach(element => {
      element.setAttribute("target","_blank");
    });
  }

  let nav = buildNav();

  // Assume page not found
  let content = '';
  let redirect = false;

  // Check for matching redirect path
  if ( Array.isArray(props.data.redirects) )
    props.data.redirects.forEach( (i) => {
      if ( props.page === i.path ) {
          redirect = true;
          window.location.replace(i.target)
      }
    })

  // Check for matching content path
  if ( Array.isArray(props.data.content) )
    props.data.content.forEach((i) => {
      //console.log("TargetNID: "+targetNID);
      if(targetNID === i.nid) {
        content = parseHtml(i.content);
      }
    });


  document.title = props.data.site_name;
  let baseURL = ( !isCustom ? "/"+props.group+"/" : "/" )


  useEffect(() => {
    setExternalTargets();
  }, []);
  
  return (
    <div>
      <HeaderSection>
        <ASUHeader
          logo={{
            alt: "logo",
            src: desktopLogo,
            mobileSrc: mobileLogo,
          }}
          baseUrl={baseURL}
          loggedIn={isAuthenticated}
          userName={user}
          onLoginClick={() => { window.open("https://sites.thecollege.asu.edu/cas"); }  }
          //onLogoutClick={() => { window.location.href = "https://thecollege.asu.edu/caslogout";setAuthenticated(false);}  }
          navTree={ nav }
          title={ props.data.site_name }
          buttons={[ ]}
        />
      </HeaderSection>
      { 
        ( props.status == 500 ?
          <ErrorPage {...props} error="" custom={isCustom} /> 
          :
          ( content.length == 0 ? 
            ( redirect ? 
              <ErrorPage {...props} error="304" custom={isCustom} />
              :
              ( props.page == undefined ?
                <ErrorPage {...props} error="500" custom={isCustom} />
                :
                <ErrorPage {...props} error="404" custom={isCustom} />
              )
            )
            :
            <div className="main-container">
            { content }
            </div>
          )
        )
      }
      <Footer></Footer>
    </div>
  )
  

}

export { Content };
